<template>
  <div class="panel">
    <LogoutBtn />
    <select v-model="mode" multiple>
      <option value="msk">Яндекс: Москва</option>
      <option value="gru">Google: Москва</option>
      <option value="spb">Яндекс: Санкт-Петербург</option>
      <option value="ekb">Яндекс: Екатеринбург</option>
      <option value="krr">Яндекс: Краснодар</option>
      <option value="nnv">Яндекс: Н. Новгород</option>
      <option value="rnd">Яндекс: Ростов-на-Дону</option>
      <option value="gkv">Google: Киев</option>
      <option value="mns">Яндекс: Минск</option>
      <option value="gmns">Google: Минск</option>
      <option value="kzn">Яндекс: Казань</option>
      <option value="oms">Яндекс: Омск</option>
      <option value="vlg">Яндекс: Волгоград</option>
      <option value="nsk">Яндекс: Новосибирск</option>
      <option value="che">Яндекс: Челябинск</option>
      <option value="sam">Яндекс: Самара</option>
      <option value="ufa">Яндекс: Уфа</option>
      <option value="kry">Яндекс: Красноярск</option>
      <option value="prm">Яндекс: Пермь</option>
      <option value="vrn">Яндекс: Воронеж</option>
      <option value="sar">Яндекс: Саратов</option>
      <option value="gny">Google: New York</option>
      <option value="tmn">Яндекс: Тюмень</option>
      <option value="zen">Дзен</option>
    </select>
    <div v-if="mode && (mode.length !== undefined)">Выбрано регионов: {{ mode.length }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoutBtn from './buttons/logoutBtn.vue';
export default {
  computed: {
    ...mapState(["Role"]),
    mode: {
      get() {
        return this.$store.state.mode
      },
      set(value) {
        this.$store.commit('setMode', value)
      }
    }
  },
  components: { LogoutBtn },

}
</script>

<style lang="sass">
.panel
  display: flex
  gap: 10px
  &__right
    margin-left: auto
  &__gap
    margin-left: 20px
  select
    margin-right: 10px
  button
    font-weight: 700
    width: 40px
    height: 40px
    max-height: 40px
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    line-height: 0
</style>