<template>
  <div class="range-input-container">
    <label v-if="label" class="range-label">{{ label }}</label>
    <div class="range-wrapper" :style="{'--value': internalValue + '%', '--val': internalValue}">
      <input
        type="range"
        v-model="internalValue"
        min="0"
        max="100"
        @input="updateValue"
        class="range-input"
      />
      <span class="range-value">{{ internalValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RangeInput",
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      internalValue: this.modelValue
    };
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit("update:modelValue", newVal);
    }
  },
  methods: {
    updateValue(event) {
      this.internalValue = event.target.value;
    }
  }
};
</script>

<style lang="sass">
.range-input-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 100%
  max-width: 300px
  --thumbwidth: 20px

  .range-label
    margin-bottom: 10px
    font-size: 1em
    color: #333

  .range-wrapper
    position: relative
    width: calc(100% - 10px)
    margin: 0px 0 25px


    .range-input
      width: 100%
      -webkit-appearance: none
      height: 8px
      background: linear-gradient(to right, #007bff 0%, #007bff var(--value), #ddd var(--value), #ddd 100%)
      outline: none
      opacity: 0.7
      transition: opacity .15s ease-in-out
      border-radius: 5px
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
      position: relative
      z-index: 1

      &::-webkit-slider-thumb
        -webkit-appearance: none
        appearance: none
        width: 20px
        height: 20px
        background: rgb(0, 123, 255)
        cursor: pointer
        border-radius: 50%
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5)
        transition: background .15s ease-in-out
        position: relative
        top: 50%
        transform: translateY(-50%)
        z-index: 2

      &::-moz-range-thumb
        width: 20px
        height: 20px
        background: rgb(0, 123, 255)
        cursor: pointer
        border-radius: 50%
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5)
        transition: background .15s ease-in-out
        position: relative
        //top: 50%
        //transform: translateY(-50%)
        z-index: 2

      &::-ms-thumb
        width: 20px
        height: 20px
        background: rgb(0, 123, 255)
        cursor: pointer
        border-radius: 50%
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5)
        transition: background .15s ease-in-out
        position: relative
        top: 50%
        transform: translateY(-50%)
        z-index: 2

      &::-webkit-slider-runnable-track
        width: 100%
        height: 8px
        background: transparent
        position: relative
        z-index: 1

      &::-moz-range-track
        width: 100%
        height: 8px
        background: transparent
        position: relative
        z-index: 1

      &::-ms-track
        width: 100%
        height: 8px
        background: transparent
        border-color: transparent
        color: transparent
        position: relative
        z-index: 1

        &::-ms-fill-lower
          background: rgb(0, 123, 255)
          border-radius: 5px

        &::-ms-fill-upper
          background: #ddd
          border-radius: 5px

      &:hover
        opacity: 1

    .range-value
      position: absolute
      top: 25px
      display: block
      left: calc(2px + var(--thumbwidth) / 2 + var(--val) / 100 * (100% - var(--thumbwidth)))
      transform-origin: center center
      transform: translateX(-50%)
      font-size: 1em
      color: rgb(0, 123, 255)
      background-color: #fff
      padding: 2px 5px
      border-radius: 5px
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
</style>