<template>
  <div class="toggle-select">
    <div
      v-for="(value, index) in values"
      :key="index"
      :class="['toggle-item', { selected: selectedValue === value }]"
      @click="selectValue(value)"
    >
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleSelect",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    values: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      selectedValue: this.modelValue || this.values[0]
    };
  },
  watch: {
    modelValue(newVal) {
      this.selectedValue = newVal;
    },
    selectedValue(newVal) {
      this.$emit("update:modelValue", newVal);
    }
  },
  methods: {
    selectValue(value) {
      this.selectedValue = value;
    }
  }
};
</script>

<style lang="sass" scoped>
.toggle-select
  display: flex
  justify-content: space-around
  align-items: center

.toggle-item
  padding: 10px 20px
  margin: 0 5px
  height: 40px
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #ccc
  border-radius: 5px
  cursor: pointer
  transition: all 0.3s ease
  background-color: white
  color: black
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)

  &.selected
    background-color: colors.$blue
    color: white
    box-shadow: 0 0 10px transparentize(colors.$blue, .5)
</style>